import * as React from "react"
import { graphql, Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { options } from "../components/options"
import { Title, Images, Nav } from "../components/styles"

const Video = loadable(() => import("../components/video"), {
  fallback: <div></div>,
})

const Film = ({ data }) => {
  const {
    film: { title, images, text, video, furtherText },
    allContentfulFilm: films,
  } = data

  const titles = films.films.map(({ title }) => title)
  const slugs = films.films.map(({ slug }) => slug)

  const index = titles.indexOf(title)

  const before = titles[index - 1]
  const after = titles[index + 1]

  return (
    <Layout>
      <Seo title={title} />
      <main>
        <Title>{title}</Title>
        {video ? <Video url={video} /> : <Images images={images} />}
        {text && <article>{renderRichText(text, options)}</article>}
        {video && (
          <Images
            images={images}
            maxImagesPerRow={4}
            jumpInterval={5000}
            frameColor="#8EB48D"
          />
        )}
        {furtherText && (
          <article>{renderRichText(furtherText, options)}</article>
        )}
      </main>
      <Nav>
        {before ? (
          <>
            <Link to={`/film/${slugs[titles.indexOf(before)]}`}>
              <h3>{before}</h3>
              <svg
                width="44"
                height="30"
                viewBox="0 0 44 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M42 17C43.1046 17 44 16.1046 44 15C44 13.8954 43.1046 13 42 13L42 17ZM0.585786 13.5858C-0.195262 14.3668 -0.195262 15.6332 0.585787 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857864C15.3611 0.076815 14.0948 0.0768151 13.3137 0.857864L0.585786 13.5858ZM42 13L2 13L2 17L42 17L42 13Z" />
              </svg>
            </Link>
          </>
        ) : (
          <div></div>
        )}
        {after ? (
          <>
            <Link to={`/film/${slugs[titles.indexOf(after)]}`}>
              <h3>{after}</h3>
              <svg
                width="44"
                height="30"
                viewBox="0 0 44 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 13C0.895431 13 -1.31719e-08 13.8954 0 15C1.31719e-08 16.1046 0.895432 17 2 17L2 13ZM43.4142 16.4142C44.1953 15.6332 44.1953 14.3668 43.4142 13.5858L30.6863 0.857865C29.9052 0.0768157 28.6389 0.0768158 27.8579 0.857865C27.0768 1.63891 27.0768 2.90524 27.8579 3.68629L39.1716 15L27.8579 26.3137C27.0768 27.0948 27.0768 28.3611 27.8579 29.1421C28.6389 29.9232 29.9052 29.9232 30.6863 29.1421L43.4142 16.4142ZM2 17L42 17L42 13L2 13L2 17Z" />
              </svg>
            </Link>
          </>
        ) : (
          <div></div>
        )}
      </Nav>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    film: contentfulFilm(slug: { eq: $slug }) {
      title
      releaseDate
      images {
        id
        title
        gatsbyImageData(
          formats: [AUTO, WEBP]
          quality: 80
          width: 880
          placeholder: DOMINANT_COLOR
        )
      }
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 80
              width: 880
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
      video
      furtherText {
        raw
      }
    }
    allContentfulFilm(
      filter: { node_locale: { eq: "de" }, title: { ne: "F_PH" } }
      sort: { fields: releaseDate, order: DESC }
    ) {
      films: nodes {
        title
        slug
      }
    }
  }
`

export default Film
